:root {
  color-scheme: dark;
}

:root body {
  font-family: 'Inter', sans-serif !important;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #fff;
  background-color: #0e1d28;
}

h1 {
  font-size: 32px;
  font-weight: bold;
  line-height: 32px;
}

h2 {
  font-size: 22px;
  font-weight: bold;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 14px;
}

p {
  font-weight: 400;
  margin: 0;
}

.subtitle {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  color: var(--captions);
  margin-bottom: 4px;
}

.table-text {
  font-size: 12px;
  color: var(--captions);
}

.center {
  display: grid;
  place-items: center;
}

/* Bold table headings to mimic Figma design */
th > span {
  font-weight: bold;
  text-transform: uppercase;
}
/* @media screen and (min-width: 1400px) and (max-width: 1500px) {
  th > span {
    font-size: 10px;
  }
} */

/* Used to center column heading */
.center {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', Lato,
    monospace;
}

#userguiding iframe {
  color-scheme: none !important;
}
